
html {
  scroll-behavior: smooth;
}

/* Navbar */

#navbar{
  background-color: black;
}

.navbar {
  background-color: black;
}

.navbar-start {
  text-align: center;
}

.link {
  color: #FFF;
  transition: .05s;
}
.link:hover {
  padding-bottom: 5px;
  color: #FFF;
  
}

nav {
  border-bottom: 0.1px solid whitesmoke;
}

div.navbar-end:hover > div {
  opacity: 0.5;
}

div.navbar-end:hover > div:hover {
  opacity: 1;
}

div.navbar-end {
  text-align: center;
}


/* Footer */

.row {
  display:flex;
  flex-direction: row;
  justify-content: center;
}

#footer {
  border-top: 0.1px solid whitesmoke;
  
}

#envelope {
  color: rgb(254, 79, 79);
}

#linkedin {
  color: #0077b5;
}

#Contactinfo {
  background-color: black;
  border-top: 0.1px solid whitesmoke;
}


/* Typewriter */
.name, .jobtitle {
  color: black;
  font-weight: bold;
}

/* About Me */


/* Projects */

.lists{
  display:inline-block;
  padding: 3px;
  margin-top: 0px;
  text-align: center;
}

.lists::before{
  content: '▫︎ ';
}

#links {
  color: rgb(14, 3, 128);
  transition: .05s;
}
#links:hover {
  color: rgb(255, 149, 0);
  text-decoration: underline;
}

#projectlink {
  border: 1px solid black;
  padding: 7px;
  background-color: black;
  border-radius: 5px;
  color: white;
}

#projectlink:hover {
  background-color: rgb(255, 149, 0);
  color: black;
}


#githublink {
  border: 1px solid black;
  padding: 7px;
  background-color: black;
  border-radius: 5px;
  color: white;
}

#githublink:hover {
  background-color: rgb(252, 0, 202);
  color: black;
}

/* Interests */
#interests{
  border-top: 0.1px solid whitesmoke;
}